<template>
  <div>
    <h3><b>การ์ดเมสเสจ</b></h3>
    ข้อความในรูปแบบการ์ดที่รวมเนื้อหาต่างๆ เอาไว้ในที่เดียว <br />
    โดยจะแสดงเนื้อหาแบบภาพสไลด์ที่ลูกค้าสามารถปัดการ์ดไปด้านข้างเพื่อดูเนื้อหาบนการ์ดอื่นได้
    <br /><br />
    <h3><b>ชื่อ </b></h3>
    <div style="margin-left: 5em">
      <b-row>
        <b-col cols="5">
          <b-form-group>
            <b-form-input v-model="name_card" maxlength="100" placeholder="ใส่ชื่อ" />
          </b-form-group>

          <b-alert v-model="check.showDismissibleAlert" v-height-fade.appear variant="danger" dismissible class="mb-0">
            <div class="alert-body">ใส่ชื่อ</div>
          </b-alert>
        </b-col>
        <b-col style="margin-top: 0.6em" cols="2">
          {{ name_card.length }}/100
        </b-col>
      </b-row>
    </div>
    <hr />
    <h3><b>ตั้งค่าการ์ด </b></h3>
    <b-row>
      <div style="margin-left: 2em">
        <b style="font-size: 18px">ประเภทการ์ด</b> &nbsp;&nbsp;
        {{ type_card }}&nbsp;&nbsp;
        <b-button variant="primary" v-b-modal.modal-type>
          <span>เลือก</span>
        </b-button>
      </div>
    </b-row>
    <hr />
    <b-row v-if="type_card">
      <b-col cols="6">
        <b-alert v-if="type_card === 'รูปภาพ' || type_card === 'บุคคล'" v-model="check.file1" v-height-fade.appear
          variant="danger" dismissible class="mb-0">
          <div class="alert-body">เลือกรูปภาพ อย่างน้อย 1 รูป</div>
        </b-alert>
        <b-alert v-if="type_card === 'สินค้า'" v-model="check.file3" v-height-fade.appear variant="danger" dismissible
          class="mb-0">
          <div class="alert-body">เลือกรูปภาพ อย่างน้อย 3 รูป</div>
        </b-alert>
      </b-col>
      <b-col cols="6">
        <div style="margin-left: 21em">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="repeateAgain">
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>เพิ่มการ์ด</span>
          </b-button>
        </div>
      </b-col></b-row>
    <div class="mb-3" v-if="type_card">
      <b-tabs align="left" v-model="tabIndex">
        <hr style="margin-top: -1em" />

        <div v-for="(item, index) in items" :id="item.id" :key="item.id" ref="row">
          <b-tab :id="'tab' + index" :title="'การ์ด ' + (index + 1)">
            <b-row>
              <b-col cols="6"></b-col>
              <b-col cols="6">
                <div style="margin-left: 18em">
                  <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="tabIndex--">
                    <feather-icon icon="ChevronLeftIcon" class="mr-25" /> </b-button>&nbsp;
                  <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="tabIndex++">
                    <feather-icon icon="ChevronRightIcon" class="mr-25" /> </b-button>&nbsp;
                  <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger"
                    @click="removeItem(index)">
                    <feather-icon icon="XIcon" class="mr-25" />การ์ด
                    {{ index + 1 }}
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <br />
            <!-- -------------- product --------------- -->
            <div v-if="type_card === 'สินค้า'" cols="12">
              <b-row>
                <b-col cols="4">
                  <!-- <b-card style="width: 360px; height: 390px">
                    <div
                      class="grid-container"
                      v-if="item.number_of_pictures === 3"
                    >
                      <div class="item1">
                        <img
                          :src="item.pictrue1"
                          style="
                            object-fit: cover;
                            object-position: center;
                            width: 190px;
                            height: 200px;
                            margin-left: -1.5em;
                            border-top-left-radius: 4px;
                            border-bottom-left-radius: 4px;
                            margin-top: -1.5em;
                          "
                        />
                      </div>
                      <div class="item2">
                        <div class="grid-container2">
                          <div class="item1">
                            <img
                              :src="item.pictrue2"
                              style="
                                object-fit: cover;
                                object-position: center;
                                width: 165px;
                                height: 100px;
                                border-top-right-radius: 4px;
                                border-bottom-right-radius: 0px;
                                margin-top: -1.5em;
                              "
                            />
                          </div>
                          <div class="item2">
                            <img
                              :src="item.pictrue3"
                              style="
                                object-fit: cover;
                                object-position: center;
                                width: 165px;
                                height: 100px;
                                border-top-right-radius: 0px;
                                border-bottom-right-radius: 4px;
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="grid-container"
                      v-if="item.number_of_pictures === 2"
                    >
                      <div class="item1">
                        <img
                          :src="item.pictrue1"
                          style="
                            object-fit: cover;
                            object-position: center;
                            width: 190px;
                            height: 200px;
                            margin-left: -1.5em;
                            border-top-left-radius: 4px;

                            margin-top: -1.5em;
                          "
                        />
                      </div>
                      <div class="item1">
                        <img
                          :src="item.pictrue2"
                          style="
                            object-fit: cover;
                            object-position: center;
                            width: 190px;
                            height: 200px;
                            margin-left: -1.5em;

                            border-top-right-radius: 4px;
                            margin-top: -1.5em;
                          "
                        />
                      </div>
                    </div>
                    <div
                      class="grid-container"
                      v-if="item.number_of_pictures === 1"
                    >
                      <div class="item1">
                        <img
                          :src="item.pictrue1"
                          style="
                            object-fit: cover;
                            object-position: center;
                            width: 360px;
                            height: 220px;
                            margin-left: -1.5em;

                            margin-top: -1.5em;
                            border-top-left-radius: 4px;
                            border-top-right-radius: 4px;
                          "
                        />
                      </div>
                    </div>

                    <b-card-text>
                      <div
                        :id="'text_banner' + index"
                        class="inner_position_top a5"
                        v-if="item.text_banner"
                      >
                        {{ item.text_banner }}
                      </div>
                      <b
                        ><br />
                        <br />
                        <h2>{{ item.name }}</h2></b
                      >
                      <h5>{{ item.description }}</h5>
                    </b-card-text>
                    <div align="right">
                      <h4>฿ {{ item.price }}</h4>
                    </div>
                  </b-card> -->
                  <div id="flex5">
                    <div class="LySlider">
                      <div class="lyInner">
                        <div class="lyItem LyMe" v-if="item.number_of_pictures === 1">
                          <div class="T1 fxLTR" dir="ltr">
                            <div class="t1Hero"></div>
                            <div class="t1Header">
                              <div class="MdBx vr" style="padding: 0px">
                                <div class="MdBx hr">
                                  <div class="MdImg ExCover fl1 ExFull grvC">
                                    <div>
                                      <a style="padding-bottom: 65%">
                                        <span :style="{
                                          'background-image':
                                            'url(' + item.pictrue1 + ')',
                                        }"></span>
                                      </a>
                                    </div>
                                  </div>

                                  <div class="
                                      MdBx
                                      hr
                                      fl0
                                      ExAbs
                                      inner_position_top
                                      a5
                                    " :id="'text_banner' + index" v-if="item.text_banner" style="
                                      width: auto;
                                      max-width: 90px;
                                      height: 25px;

                                      border-radius: 100px;
                                      top: 18px;
                                      left: 18px;
                                      padding: 2px;
                                      padding-left: 4px;
                                      padding-right: 4px;
                                    ">
                                    <div class="ExAlgC grvC ExXs" >
                                      <p>{{ item.text_banner }}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="t1Body">
                              <div class="MdBx vr" style="
                                  background-color: #fff !important ;
                                  padding: 20px;
                                ">
                                <div align="left">
                                  <h3>{{ item.name }}</h3>
                                </div>
                                <div align="left">
                                  <p>{{ item.description }}</p>
                                </div>
                                <div align="right">฿ {{ item.price }}</div>
                              </div>
                            </div>
                            <div class="t1Footer"></div>
                          </div>
                        </div>

                        <div class="lyItem LyMe" v-if="item.number_of_pictures === 2">
                          <div class="T1 fxLTR" dir="ltr">
                            <div class="t1Hero"></div>
                            <div class="t1Header">
                              <div class="MdBx vr" style="padding: 0px">
                                <div class="MdBx hr">
                                  <div class="MdImg ExCover fl1 ExFull grvC">
                                    <div>
                                      <a style="
                                          padding-bottom: 130.66666666666666%;
                                        ">
                                        <span :style="{
                                          'background-image':
                                            'url(' + item.pictrue1 + ')',
                                        }"></span>
                                      </a>
                                    </div>
                                  </div>
                                  <div class="MdImg ExCover fl1 ExFull grvC">
                                    <div>
                                      <a style="
                                          padding-bottom: 130.66666666666666%;
                                        ">
                                        <span :style="{
                                          'background-image':
                                            'url(' + item.pictrue2 + ')',
                                        }"></span>
                                      </a>
                                    </div>
                                  </div>

                                  <div class="
                                      MdBx
                                      hr
                                      fl0
                                      ExAbs
                                      inner_position_top
                                      a5
                                    " :id="'text_banner' + index" v-if="item.text_banner" style="
                                      width: auto;
                                      max-width: 90px;
                                      height: 25px;
                                      border-radius: 100px;
                                      top: 18px;
                                      left: 18px;
                                      padding: 2px;
                                      padding-left: 4px;
                                      padding-right: 4px;
                                    ">
                                    <div class="ExAlgC grvC ExXs" >
                                      <p>{{ item.text_banner }}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="t1Body">
                              <div class="MdBx vr" style="
                                  background-color: #fff !important ;
                                  padding: 20px;
                                ">
                                <div align="left">
                                  <h3>{{ item.name }}</h3>
                                </div>
                                <div align="left">
                                  <p>{{ item.description }}</p>
                                </div>
                                <div align="right">฿ {{ item.price }}</div>
                              </div>
                            </div>
                            <div class="t1Footer"></div>
                          </div>
                        </div>

                        <div class="lyItem LyMe" v-if="item.number_of_pictures === 3">
                          <div class="T1 fxLTR" dir="ltr">
                            <div class="t1Hero"></div>
                            <div class="t1Header">
                              <div class="MdBx vr" style="padding: 0px">
                                <div class="MdBx hr">
                                  <div class="MdImg ExCover fl1 ExFull grvC">
                                    <div>
                                      <a style="
                                          padding-bottom: 130.66666666666666%;
                                        ">
                                        <span :style="{
                                          'background-image':
                                            'url(' + item.pictrue1 + ')',
                                        }"></span>
                                      </a>
                                    </div>
                                  </div>
                                  <div class="MdBx vr fl1">
                                    <div class="MdImg ExCover ExFull grvC">
                                      <div>
                                        <a style="
                                            padding-bottom: 65.33333333333333%;
                                          ">
                                          <span :style="{
                                            'background-image':
                                              'url(' + item.pictrue2 + ')',
                                          }"></span>
                                        </a>
                                      </div>
                                    </div>
                                    <div class="MdImg ExCover ExFull grvC">
                                      <div>
                                        <a style="
                                            padding-bottom: 65.33333333333333%;
                                          ">
                                          <span :style="{
                                            'background-image':
                                              'url(' + item.pictrue3 + ')',
                                          }"></span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="
                                      MdBx
                                      hr
                                      fl0
                                      ExAbs
                                      inner_position_top
                                      a5
                                    " :id="'text_banner' + index" v-if="item.text_banner" style="
                                      width: auto;
                                      max-width: 90px;
                                      height: 25px;

                                      border-radius: 100px;
                                      top: 18px;
                                      left: 18px;
                                      padding: 2px;
                                      padding-left: 4px;
                                      padding-right: 4px;
                                    ">
                                    <div class="ExAlgC grvC ExXs" >
                                      <p>{{ item.text_banner }}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="t1Body">
                              <div class="MdBx vr" style="
                                  background-color: #fff !important ;
                                  padding: 20px;
                                ">
                                <div align="left">
                                  <h3>{{ item.name }}</h3>
                                </div>
                                <div align="left">
                                  <p>{{ item.description }}</p>
                                </div>
                                <div align="right">฿ {{ item.price }}</div>
                              </div>
                            </div>
                            <div class="t1Footer"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                </b-col>
                <b-col cols="8">
                  <b-card class="text-center" style="width: 90%; height: 740px">
                    <b-col md="12" xl="12"> </b-col>
                    <br />
                    <b-row>
                      <b-col style="margin-top: 0.6em" cols="3">แบนเนอร์</b-col>
                      <b-col cols="7">
                        <b-form-group label-for="basicInput">
                          <b-form-input id="basicInput" v-model="item.text_banner" maxlength="10"
                            placeholder="ใส่ข้อความแบนเนอร์" />
                        </b-form-group>
                      </b-col>
                      <b-col style="margin-top: 0.6em" cols="2">
                        {{ item.text_banner.length }}/10
                      </b-col>
                    </b-row>

                    <span class="dot a1" id="a1" @click="setclass('a1', index)">A</span>
                    <span class="dot a2" id="a2" @click="setclass('a2', index)">A</span>
                    <span class="dot a3" id="a3" @click="setclass('a3', index)">A</span>
                    <span class="dot a4" id="a4" @click="setclass('a4', index)">A</span>
                    <span class="dot a5" id="a5" @click="setclass('a5', index)">A</span>
                    <span class="dot a6" id="a6" @click="setclass('a6', index)">A</span>
                    <br /><br />
                    <b-row>
                      <b-col style="margin-top: 0.6em" cols="3">ชื่อการ์ด</b-col>
                      <b-col cols="7">
                        <b-form-group label-for="basicInput">
                          <b-form-input id="basicInput" v-model="item.name" maxlength="10" placeholder="ใส่ชื่อการ์ด" />
                        </b-form-group>
                      </b-col>
                      <b-col style="margin-top: 0.6em" cols="2">
                        {{ item.text_banner.length }}/10
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col style="margin-top: 0.6em" cols="3">รูป</b-col>
                      <b-col cols="7">
                        <b-form-select v-model="item.number_of_pictures" :options="item.options" size="sm"
                          class="mt-1" />
                      </b-col>
                    </b-row>
                    <br />
                    <!----------description------ -->
                    <b-row>
                      <b-col style="margin-top: 0.6em" cols="3">คำอธิบาย</b-col>
                      <b-col cols="7">
                        <b-form-group label-for="basicInput">
                          <b-form-textarea id="basicInput" v-model="item.description" maxlength="60"
                            placeholder="ใส่คำอธิบาย" />
                        </b-form-group>
                      </b-col>
                      <b-col style="margin-top: 0.6em" cols="2">
                        {{ item.description.length }}/60
                      </b-col>
                    </b-row>
                    <!----------price------ -->
                    <b-row>
                      <b-col style="margin-top: 0.6em" cols="3">ราคา </b-col>
                      <b-col cols="7">
                        <b-form-group>
                          <b-form-input v-model="item.price" maxlength="10" placeholder="ราคา" />
                        </b-form-group>
                      </b-col>
                      <b-col style="margin-top: 0.6em" cols="2">
                        {{ item.price.length }}/10
                      </b-col>
                    </b-row>
                    <!----------link------ -->
                    <b-row>
                      <b-col style="margin-top: 0.6em" cols="3">ลิงค์</b-col>
                      <b-col cols="7">
                        <b-form-group>
                          <b-form-input v-model="item.link" placeholder="ลิงค์" />
                        </b-form-group>
                      </b-col>
                      <b-col style="margin-top: 0.6em" cols="2"> </b-col>
                    </b-row>
                    <div class="mt-2"></div>
                    <b-row>
                      <b-col cols="4">
                        <div class="grid-container3" v-if="item.number_of_pictures == 3">
                          <div class="item1" @click="open_product1(1, index)"
                            style="margin: 10px; border: 1px solid rgb(0, 0, 0)">
                            <!-- รูปที่ 1 -->
                            <img :src="item.pictrue1" style="
                                object-fit: cover;
                                object-position: center;
                                width: 190px;
                                height: 200px;
                              " />
                          </div>
                          <div class="item2" @click="open_product2(2, index)"
                            style="margin: 10px; border: 1px solid rgb(0, 0, 0)">
                            <!-- รูปที่ 2 -->
                            <br /><br />

                            <img :src="item.pictrue2" style="
                                object-fit: cover;
                                object-position: center;
                                width: 165px;
                                height: 100px;
                              " />
                          </div>
                          <div class="item3" @click="open_product3(3, index)"
                            style="margin: 10px; border: 1px solid rgb(0, 0, 0)">
                            <!-- รูปที่ 3 -->
                            <br /><br />
                            <img :src="item.pictrue3" style="
                                object-fit: cover;
                                object-position: center;
                                width: 165px;
                                height: 100px;
                              " />
                          </div>
                        </div>
                        <div class="grid-container3" v-if="item.number_of_pictures == 2">
                          <div class="item1" @click="open_product2_type1(1, index)"
                            style="margin: 10px; border: 1px solid rgb(0, 0, 0)">
                            <!-- รูปที่ 1 -->
                            <img :src="item.pictrue1" style="
                                object-fit: cover;
                                object-position: center;
                                width: 190px;
                                height: 200px;
                              " />
                          </div>
                          <div class="item2" @click="open_product2_type2(2, index)"
                            style="margin: 10px; border: 1px solid rgb(0, 0, 0)">
                            <!-- รูปที่ 2 -->

                            <img :src="item.pictrue2" style="
                                object-fit: cover;
                                object-position: center;
                                width: 190px;
                                height: 200px;
                              " />
                          </div>
                        </div>
                        <div class="grid-container3" v-if="item.number_of_pictures == 1">
                          <div class="item1" @click="open_product1_type1(1, index)"
                            style="margin: 10px; border: 1px solid rgb(0, 0, 0)">
                            <!-- รูปที่ 1 -->
                            <img :src="item.pictrue1" style="
                                object-fit: cover;
                                object-position: center;
                                width: 355px;
                                height: 220px;
                              " />
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </div>
            <!-- -------------- profile --------------- -->

            <div v-if="type_card === 'บุคคล'">
              <b-row>
                <b-col cols="4">
                  <div class="text-center">
                    <b-col md="12" xl="12">
                      <!-- <div>
                        <b-card style="width: 360px; height: 390px">
                          <div>
                            <img
                              :src="item.pictrue1"
                              width="220px"
                              height="220px"
                              style="border-radius: 50%"
                            />
                            <br /><br />
                          </div>
                          <div v-if="item.name">
                            <h3>{{ item.name }}</h3>
                          </div>

                          <span
                            v-if="item.tag1"
                            :id="'tag1' + index"
                            class="mr-1 pl-1"
                            variant="light-primary"
                          >
                            {{ item.tag1 }}
                          </span>
                          <span
                            v-if="item.tag2"
                            :id="'tag2' + index"
                            class="mr-1"
                            variant="light-success"
                          >
                            {{ item.tag2 }}
                          </span>
                          <span
                            v-if="item.tag3"
                            :id="'tag3' + index"
                            class="mr-1"
                            variant="light-info"
                          >
                            {{ item.tag3 }} </span
                          ><br /><br />
                          <h4>{{ item.description }}</h4>
                        </b-card>
                      </div> -->
                      <div id="flex6">
                        <div class="LySlider">
                          <div class="lyInner">
                            <div class="lyItem LyMe">
                              <div class="T1 fxLTR" dir="ltr">
                                <div class="t1Hero"></div>
                                <div class="t1Header">
                                  <div align="center" class="mt-2 mb-2">
                                    <img :src="item.pictrue1" width="120px" height="120px" style="border-radius: 50%" />
                                  </div>
                                </div>
                                <div class="t1Body">
                                  <h3 v-if="item.name" class="mb-1">
                                    {{ item.name }}
                                  </h3>
                                  <div align="center" style="
                                      display: flex;
                                      justify-content: center;
                                      align-items: center;
                                    ">
                                    <div v-if="item.tag1" :id="'tag1' + index" class="mr-1 a3" style="
                                        width: 63px;
                                        max-width: 63px;
                                        height: 25px;

                                        border-radius: 20px;
                                        top: 18px;
                                        left: 18px;
                                        float: left !important;
                                      ">
                                      <div class="MdTxt ExAlgC ExXs" style=" top: 3px">
                                        <p>{{ item.tag1 }}</p>
                                      </div>
                                    </div>
                                    <div v-if="item.tag2" :id="'tag2' + index" class="mr-1 a5" style="
                                        width: 63px;
                                        max-width: 63px;
                                        height: 25px;

                                        border-radius: 20px;
                                        top: 18px;
                                        left: 18px;
                                        float: left !important;
                                      ">
                                      <div class="MdTxt ExAlgC ExXs" style=" top: 3px">
                                        <p>{{ item.tag2 }}</p>
                                      </div>
                                    </div>
                                    <div v-if="item.tag3" :id="'tag3' + index" class="mr-1 a1" style="
                                        width: 63px;
                                        max-width: 63px;
                                        height: 25px;

                                        border-radius: 20px;
                                        top: 18px;
                                        left: 18px;
                                        float: left !important;
                                      ">
                                      <div class="MdTxt ExAlgC ExXs" style="top: 3px">
                                        <p>{{ item.tag3 }}</p>
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                  <h6>{{ item.description }}</h6>
                                  <br /><br />
                                </div>
                                <div class="t1Footer"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </div>
                </b-col>
                <b-col cols="8">
                  <b-card class="text-center" style="width: 90%; height: 900px">
                    <b-row>
                      <b-col style="margin-top: 0.6em" cols="3">รูป</b-col>
                      <b-col cols="7">
                        <div @click="open_profile(21, index)" align="center" style="margin-left: -16em">
                          <img :src="item.pictrue1" style="
                              object-fit: cover;
                              object-position: center;
                              width: 200px;
                              height: 200px;
                            " />
                        </div>
                      </b-col>
                      <b-col cols="2"></b-col>
                    </b-row>
                    <br /><br />
                    <!----------name------ -->
                    <b-row>
                      <b-col style="margin-top: 0.6em" cols="3">ชื่อ</b-col>
                      <b-col cols="7">
                        <b-form-group label-for="basicInput">
                          <b-form-input id="basicInput" v-model="item.name" maxlength="20" placeholder="ใส่ชื่อ" />
                        </b-form-group>
                      </b-col>
                      <b-col style="margin-top: 0.6em" cols="2">
                        {{ item.text_banner.length }}/20
                      </b-col>
                    </b-row>

                    <!----------tag1------ -->
                    <b-row>
                      <b-col style="margin-top: 0.6em" cols="3">แท็ก 1</b-col>
                      <b-col cols="7">
                        <b-form-group label-for="basicInput">
                          <b-form-input id="basicInput" v-model="item.tag1" maxlength="10"
                            placeholder="ใส่ข้อความบนแบนเนอร์" />
                        </b-form-group>
                      </b-col>
                      <b-col style="margin-top: 0.6em" cols="2">
                        {{ item.tag1.length }}/10
                      </b-col>
                    </b-row>
                    <div align="center">
                      <span class="dot a1" id="t11" @click="setclassTag('a1', 'tag1', index)">A</span>
                      <span class="dot a2" id="t12" @click="setclassTag('a2', 'tag1', index)">A</span>
                      <span class="dot a3" id="t13" @click="setclassTag('a3', 'tag1', index)">A</span>
                      <span class="dot a4" id="t14" @click="setclassTag('a4', 'tag1', index)">A</span>
                      <span class="dot a5" id="t15" @click="setclassTag('a5', 'tag1', index)">A</span>
                      <span class="dot a6" id="t16" @click="setclassTag('a6', 'tag1', index)">A</span>
                    </div>
                    <div class="mt-2"></div>
                    <!----------tag2------ -->
                    <b-row>
                      <b-col style="margin-top: 0.6em" cols="3">แท็ก 2</b-col>
                      <b-col cols="7">
                        <b-form-group label-for="basicInput">
                          <b-form-input id="basicInput" v-model="item.tag2" maxlength="10"
                            placeholder="ใส่ข้อความบนแบนเนอร์" />
                        </b-form-group>
                      </b-col>
                      <b-col style="margin-top: 0.6em" cols="2">
                        {{ item.tag2.length }}/10
                      </b-col>
                    </b-row>
                    <div align="center">
                      <span class="dot a1" id="t21" @click="setclassTag('a1', 'tag2', index)">A</span>
                      <span class="dot a2" id="t22" @click="setclassTag('a2', 'tag2', index)">A</span>
                      <span class="dot a3" id="t23" @click="setclassTag('a3', 'tag2', index)">A</span>
                      <span class="dot a4" id="t24" @click="setclassTag('a4', 'tag2', index)">A</span>
                      <span class="dot a5" id="t25" @click="setclassTag('a5', 'tag2', index)">A</span>
                      <span class="dot a6" id="t26" @click="setclassTag('a6', 'tag2', index)">A</span>
                    </div>
                    <div class="mt-2"></div>
                    <!----------tag3------ -->
                    <b-row>
                      <b-col style="margin-top: 0.6em" cols="3">แท็ก 3</b-col>
                      <b-col cols="7">
                        <b-form-group label-for="basicInput">
                          <b-form-input id="basicInput" v-model="item.tag3" maxlength="10"
                            placeholder="ใส่ข้อความบนแบนเนอร์" />
                        </b-form-group>
                      </b-col>
                      <b-col style="margin-top: 0.6em" cols="2">
                        {{ item.tag3.length }}/10
                      </b-col>
                    </b-row>
                    <div align="center">
                      <span class="dot a1" id="t31" @click="setclassTag('a1', 'tag3', index)">A</span>
                      <span class="dot a2" id="t32" @click="setclassTag('a2', 'tag3', index)">A</span>
                      <span class="dot a3" id="t33" @click="setclassTag('a3', 'tag3', index)">A</span>
                      <span class="dot a4" id="t34" @click="setclassTag('a4', 'tag3', index)">A</span>
                      <span class="dot a5" id="t35" @click="setclassTag('a5', 'tag3', index)">A</span>
                      <span class="dot a6" id="t36" @click="setclassTag('a6', 'tag3', index)">A</span>
                    </div>
                    <div class="mt-2"></div>

                    <!----------link------ -->
                    <b-row>
                      <b-col style="margin-top: 0.6em" cols="3">ลิงค์</b-col>
                      <b-col cols="7">
                        <b-form-group>
                          <b-form-input v-model="item.link" placeholder="ลิงค์" />
                        </b-form-group>
                      </b-col>
                      <b-col style="margin-top: 0.6em" cols="2"> </b-col>
                    </b-row>
                    <div class="mt-2"></div>
                    <!----------description------ -->
                    <b-row>
                      <b-col style="margin-top: 0.6em" cols="3">คำอธิบาย</b-col>
                      <b-col cols="7">
                        <b-form-group label-for="basicInput">
                          <b-form-textarea id="basicInput" v-model="item.description" maxlength="60"
                            placeholder="ใส่คำอธิบาย" />
                        </b-form-group>
                      </b-col>
                      <b-col style="margin-top: 0.6em" cols="2">
                        {{ item.description.length }}/60
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </div>

            <!-- -------------- img --------------- -->
            <div v-if="type_card === 'รูปภาพ'">
              <b-row>
                <b-col cols="4">
                  <!-- <div class="text-center">
                    <b-col md="12" xl="12">
                      <img
                        :src="item.pictrue1"
                        width="360px"
                        height="360px"
                        style="border-radius: 10px; margin-right: 35em"
                      />
                      <div
                        :id="'text_banner' + index"
                        class="inner_position_top a5"
                        v-if="item.text_banner"
                        style="margin-left: 0.3em"
                      >
                        {{ item.text_banner }}
                      </div>
                    </b-col>
                  </div> -->

                  <div id="flex2">
                    <div class="LySlider">
                      <div class="lyInner">
                        <div class="lyItem LyMe">
                          <div class="T1 fxLTR" dir="ltr">
                            <div class="t1Hero"></div>
                            <div class="t1Header"></div>
                            <div class="t1Body">
                              <div class="MdBx vr" style="padding: 0px">
                                <div class="MdImg ExCover ExFull">
                                  <div>
                                    <a style="padding-bottom: 100%">
                                      <span :style="{
                                        'background-image':
                                          'url(' + item.pictrue1 + ')',
                                      }"></span>
                                    </a>
                                  </div>
                                </div>

                                <div class="MdBx vr ExAbs inner_position_top a5" :id="'text_banner' + index"
                                  v-if="item.text_banner" style="
                                    width: 85px;
                                    max-width: 85px;
                                    height: 25px;
                                    border-radius: 20px;
                                    top: 18px;
                                    left: 18px;
                                  ">
                                  <div class="MdTxt ExAlgC ExXs" style="margin-top: -2px">
                                    <p>{{ item.text_banner }}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="t1Footer"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col cols="8">
                  <b-card class="text-center" style="width: 90%; height: 500px">
                    <div style="margin-left: 1em">
                      <b-row>
                        <b-col style="margin-top: 0.6em" cols="3">แบนเนอร์</b-col>
                        <b-col cols="7">
                          <b-form-group label-for="basicInput">
                            <b-form-input id="basicInput" v-model="item.text_banner" maxlength="10"
                              placeholder="ใส่ข้อความแบนเนอร์" />
                          </b-form-group>
                        </b-col>
                        <b-col style="margin-top: 0.6em" cols="2">
                          {{ item.text_banner.length }}/10
                        </b-col>
                      </b-row>
                      <div align="center">
                        <span class="dot a1" id="a1" @click="setclass('a1', index)">A</span>
                        <span class="dot a2" id="a2" @click="setclass('a2', index)">A</span>
                        <span class="dot a3" id="a3" @click="setclass('a3', index)">A</span>
                        <span class="dot a4" id="a4" @click="setclass('a4', index)">A</span>
                        <span class="dot a5" id="a5" @click="setclass('a5', index)">A</span>
                        <span class="dot a6" id="a6" @click="setclass('a6', index)">A</span>
                      </div>
                      <br />
                      <!----------link------ -->
                      <b-row>
                        <b-col style="margin-top: 0.6em" cols="3">ลิงค์</b-col>
                        <b-col cols="7">
                          <b-form-group>
                            <b-form-input v-model="item.link" placeholder="ลิงค์" />
                          </b-form-group>
                        </b-col>
                        <b-col style="margin-top: 0.6em" cols="2"> </b-col>
                      </b-row>
                      <div class="mt-2"></div>
                      <br />
                      <b-row>
                        <b-col style="margin-top: 0.6em" cols="3">รูป</b-col>
                        <b-col cols="7">
                          <div @click="open_img(31, index)" align="center" style="margin-left: -16em">
                            <img :src="item.pictrue1" style="
                                object-fit: cover;
                                object-position: center;
                                width: 200px;
                                height: 200px;
                              " />
                          </div>
                        </b-col>
                        <b-col cols="2"></b-col>
                      </b-row>
                      <br /><br />
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </div>
          </b-tab>
        </div>
        <b-tab id="tab10" title="การ์ดปิดท้าย">
          <b-row>
            <b-col cols="6"></b-col>
            <b-col cols="6">
              <div style="margin-left: 18em">
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="tabIndex--">
                  <feather-icon icon="ChevronLeftIcon" class="mr-25" /> </b-button>&nbsp;
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="tabIndex++">
                  <feather-icon icon="ChevronRightIcon" class="mr-25" />
                </b-button>
              </div>
            </b-col>
          </b-row>
          <br />
          <b-row>
            <b-col cols="4">
              <div class="text-center">
                <b-col md="12" xl="12">
                  <div>
                    <div v-if="type_end == 'รูป'">
                      <b-card style="width: 350px; padding-bottom: 5%" :img-src="pictrue_end" class="mb-3">
                        <a :href="link_end" target="_blank">
                          <h4>{{ text_end }}</h4>
                        </a>
                      </b-card>
                    </div>
                    <div v-else>
                      <b-card style="
                          width: 350px;
                          padding-top: 40%;
                          padding-bottom: 30%;
                        ">
                        <b-card-text>
                          <a :href="link_end" target="_blank">
                            <h4>{{ text_end }}</h4>
                          </a>
                        </b-card-text>
                      </b-card>
                    </div>
                  </div>
                  <br />
                </b-col>
              </div>
            </b-col>
            <b-col cols="8">
              <b-card style="width: 90%; height: 600px">
                <div>
                  <h3><b>การ์ดปิดท้าย</b></h3>
                </div>
                การ์ดนี้จะแสดงเป็นใบสุดท้ายในการ์ดเมสเสจ
                คุณสามารถใส่ลิงก์สำหรับผู้ที่ต้องการดูรายละเอียดเพิ่มเติมได้
                <br /><br />
                <!-- -----------เทมเพลต----------- -->
                <b-row>
                  <b-col cols="3">เทมเพลต</b-col>
                  <b-col cols="7">
                    {{ type_end }}
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-sm variant="outline-primary">
                      เปลี่ยน
                    </b-button>
                  </b-col>
                  <b-col cols="2"></b-col>
                </b-row>
                <br />
                <!-- -----------เลือกรูป----------- -->
                <b-row v-if="type_end == 'รูป'">
                  <b-col style="margin-top: 0.6em" cols="3">รูป</b-col>
                  <b-col cols="7">
                    <div @click="img_card_end()" align="center" style="margin-left: -16em">
                      <img :src="pictrue_end" style="
                          object-fit: cover;
                          object-position: center;
                          width: 200px;
                          height: 200px;
                        " />
                    </div>
                  </b-col>
                  <b-col cols="2"></b-col>
                </b-row>
                <b-alert v-model="check.pictrue_end" v-height-fade.appear variant="danger" dismissible class="mb-0">
                  <div class="alert-body">เพิ่มรูปภาพ</div>
                </b-alert>
                <br /><br />
                <!----------text------ -->
                <b-row>
                  <b-col style="margin-top: 0.6em" cols="3">แอ็กชัน</b-col>
                  <b-col cols="7">
                    <b-form-group label-for="basicInput">
                      <b-form-input id="basicInput" v-model="text_end" maxlength="30" placeholder="ดูเพิ่มเติม" />
                    </b-form-group>
                  </b-col>

                  <b-col style="margin-top: 0.6em" cols="2">
                    {{ text_end.length }}/30
                  </b-col>
                </b-row>
                <b-alert v-model="check.text_end" v-height-fade.appear variant="danger" dismissible class="mb-0">
                  <div class="alert-body">ใส่แอ็กชัน</div>
                </b-alert>
                <br />
                <!----------link------ -->
                <b-row>
                  <b-col style="margin-top: 0.6em" cols="3">ลิงค์</b-col>
                  <b-col cols="7">
                    <b-form-group>
                      <b-form-input v-model="link_end" placeholder="ลิงค์" />
                    </b-form-group>
                  </b-col>
                  <b-col style="margin-top: 0.6em" cols="2"> </b-col> </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </div>
    <hr />
    <div align="center">
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1" @click="save()">
        บันทึก
      </b-button>
    </div>
    <!-- -------- profile ---------- -->
    <b-modal v-model="set_profile" id="modal-center" centered size="lg" title="เลือกประเภทการ์ด" ok-only
      ok-variant="danger" ok-title="ยกเลิก" no-close-on-backdrop hide-footer hide-header-close>
      <div>
        <b-form-group>
          <b-form-file v-model="file1" placeholder="รูปภาพ" type="file" accept=".jpeg,.png,.jpg" :hidden="true"
            @change="onFileChange" size="sm" />
          <cropper style="height: 470px" ref="cropper" stencil-component="circle-stencil" image-restriction="stencil"
            :stencil-size="stencilSize" :stencil-props="{
              lines: {},
              handlers: {},
              movable: false,
              scalable: false,
              aspectRatio: 1,
              previewClass: 'twitter-cropper__stencil',
            }" :transitions="false" :canvas="true" :debounce="false" :default-size="defaultSize" :min-width="170"
            :min-height="170" :src="items[total].pictrue1" @change="onChange" />
          <navigation :zoom="zoom" @change="onZoom" />
          <div align="center">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="" @click="crop()">
              ใช้ </b-button>&nbsp;&nbsp;&nbsp;
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="danger" @click="open_default()">
              ปิด
            </b-button>
          </div>
        </b-form-group>
      </div>
    </b-modal>
    <!-- -------- img ---------- -->
    <b-modal v-model="set_img" id="modal-center" centered size="lg" title="เลือกประเภทการ์ด" ok-only ok-variant="danger"
      ok-title="ยกเลิก" no-close-on-backdrop hide-footer hide-header-close>
      <div>
        <!-- -------- img ---------- -->
        <b-form-group>
          <b-form-file v-model="file1" placeholder="รูปภาพ" type="file" accept=".jpeg,.png,.jpg" :hidden="true"
            @change="onFileChange" size="sm" />

          <cropper style="height: 470px" ref="cropper" class="twitter-cropper"
            background-class="twitter-cropper__background" foreground-class="twitter-cropper__foreground"
            image-restriction="stencil" :stencil-size="stencilSize" :stencil-props="{
              lines: {},
              handlers: {},
              movable: false,
              scalable: false,
              aspectRatio: 1,
              previewClass: 'twitter-cropper__stencil',
            }" :transitions="false" :canvas="true" :debounce="false" :default-size="defaultSize" :min-width="170"
            :min-height="170" :src="items[total].pictrue1" @change="onChange" />
          <navigation :zoom="zoom" @change="onZoom" />
          <div align="center">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="" @click="crop()">
              ใช้ </b-button>&nbsp;&nbsp;&nbsp;
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="danger" @click="open_default()">
              ปิด
            </b-button>
          </div>
        </b-form-group>
      </div>
    </b-modal>
    <!-- -------- porduct 1 type 3 ---------- -->
    <b-modal v-model="set_product1" id="modal-center" centered size="lg" title="เลือกประเภทการ์ด" ok-only
      ok-variant="danger" ok-title="ยกเลิก" no-close-on-backdrop hide-footer hide-header-close>
      <div>
        <!-- ---1--- -->
        <div>
          <b-form-group>
            <b-form-file v-model="file1" placeholder="รูปภาพ" drop-placeholder="Drop file here..." type="file"
              accept=".jpeg,.png,.jpg" :hidden="true" @change="onFileChange" size="sm" />

            <cropper style="height: 470px" ref="cropper" class="twitter-cropper"
              background-class="twitter-cropper__background" foreground-class="twitter-cropper__foreground"
              image-restriction="stencil" :stencil-size="stencilSize" :stencil-props="{
                lines: {},
                handlers: {},
                movable: false,
                scalable: false,
                aspectRatio: 10 / 12,
                previewClass: 'twitter-cropper__stencil',
              }" :transitions="false" :canvas="true" :debounce="false" :default-size="defaultSize" :min-width="170"
              :min-height="170" :src="items[total].pictrue1" @change="onChange" />
            <navigation :zoom="zoom" @change="onZoom" />
            <div align="center">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="" @click="crop()">
                ใช้ </b-button>&nbsp;&nbsp;&nbsp;
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="danger"
                @click="open_default()">
                ปิด
              </b-button>
            </div>
          </b-form-group>
        </div>
      </div>
    </b-modal>
    <!-- -------- porduct 2 type 3---------- -->
    <b-modal v-model="set_product2" id="modal-center" centered size="lg" title="เลือกประเภทการ์ด" ok-only
      ok-variant="danger" ok-title="ยกเลิก" no-close-on-backdrop hide-footer hide-header-close>
      <div>
        <!-- ---2--- -->
        <div>
          <b-form-group>
            <b-form-file v-model="file2" placeholder="รูปภาพ" drop-placeholder="Drop file here..." type="file"
              accept=".jpeg,.png,.jpg" :hidden="true" @change="onFileChange2" size="sm" />

            <cropper style="height: 470px" ref="cropper" class="twitter-cropper"
              background-class="twitter-cropper__background" foreground-class="twitter-cropper__foreground"
              image-restriction="stencil" :stencil-size="stencilSize" :stencil-props="{
                lines: {},
                handlers: {},
                movable: false,
                scalable: false,
                aspectRatio: 10 / 6.3,
                previewClass: 'twitter-cropper__stencil',
              }" :transitions="false" :canvas="true" :debounce="false" :default-size="defaultSize" :min-width="170"
              :min-height="170" :src="items[total].pictrue2" @change="onChange" />
            <navigation :zoom="zoom" @change="onZoom" />
            <div align="center">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="" @click="crop2()">
                ใช้ </b-button>&nbsp;&nbsp;&nbsp;
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="danger"
                @click="open_default()">
                ปิด
              </b-button>
            </div>
          </b-form-group>
        </div>
      </div>
    </b-modal>
    <!-- -------- porduct 3 type 3---------- -->
    <b-modal v-model="set_product3" id="modal-center" centered size="lg" title="เลือกประเภทการ์ด" ok-only
      ok-variant="danger" ok-title="ยกเลิก" no-close-on-backdrop hide-footer hide-header-close>
      <div>
        <!-- ---3--- -->
        <div>
          <b-form-group>
            <b-form-file v-model="file3" placeholder="รูปภาพ" drop-placeholder="Drop file here..." type="file"
              accept=".jpeg,.png,.jpg,GIF" :hidden="true" @change="onFileChange3" size="sm" />

            <cropper style="height: 470px" ref="cropper" class="twitter-cropper"
              background-class="twitter-cropper__background" foreground-class="twitter-cropper__foreground"
              image-restriction="stencil" :stencil-size="stencilSize" :stencil-props="{
                lines: {},
                handlers: {},
                movable: false,
                scalable: false,
                aspectRatio: 10 / 6.3,
                previewClass: 'twitter-cropper__stencil',
              }" :transitions="false" :canvas="true" :debounce="false" :default-size="defaultSize" :min-width="170"
              :min-height="170" :src="items[total].pictrue3" @change="onChange" />
            <navigation :zoom="zoom" @change="onZoom" />
            <div align="center">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="" @click="crop3()">
                ใช้ </b-button>&nbsp;&nbsp;&nbsp;
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="danger"
                @click="open_default()">
                ปิด
              </b-button>
            </div>
          </b-form-group>
        </div>
      </div>
    </b-modal>
    <!-- -------- porduct 1 type 1 ---------- -->
    <b-modal v-model="set_product1_type1" id="modal-center" centered size="lg" title="เลือกประเภทการ์ด" ok-only
      ok-variant="danger" ok-title="ยกเลิก" no-close-on-backdrop hide-footer hide-header-close>
      <div>
        <!-- ---1--- -->
        <div>
          <b-form-group>
            <b-form-file v-model="file1" placeholder="รูปภาพ สินค้า 1" drop-placeholder="Drop file here..." type="file"
              accept=".jpeg,.png,.jpg" :hidden="true" @change="onFileChange" size="sm" />

            <cropper style="height: 470px" ref="cropper" class="twitter-cropper"
              background-class="twitter-cropper__background" foreground-class="twitter-cropper__foreground"
              image-restriction="stencil" :stencil-size="stencilSize" :stencil-props="{
                lines: {},
                handlers: {},
                movable: false,
                scalable: false,
                aspectRatio: 12 / 8,
                previewClass: 'twitter-cropper__stencil',
              }" :transitions="false" :canvas="true" :debounce="false" :default-size="defaultSize" :min-width="170"
              :min-height="170" :src="items[total].pictrue1" @change="onChange" />
            <navigation :zoom="zoom" @change="onZoom" />
            <div align="center">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="" @click="crop()">
                ใช้ </b-button>&nbsp;&nbsp;&nbsp;
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="danger"
                @click="open_default()">
                ปิด
              </b-button>
            </div>
          </b-form-group>
        </div>
      </div>
    </b-modal>
    <!-- -------- porduct 2 type 1 ---------- -->
    <b-modal v-model="set_product2_type1" id="modal-center" centered size="lg" title="เลือกประเภทการ์ด" ok-only
      ok-variant="danger" ok-title="ยกเลิก" no-close-on-backdrop hide-footer hide-header-close>
      <div>
        <!-- ---1--- -->
        <div>
          <b-form-group>
            <b-form-file v-model="file1" placeholder="รูปภาพ สินค้า 1" drop-placeholder="Drop file here..." type="file"
              accept=".jpeg,.png,.jpg" :hidden="true" @change="onFileChange" size="sm" />

            <cropper style="height: 470px" ref="cropper" class="twitter-cropper"
              background-class="twitter-cropper__background" foreground-class="twitter-cropper__foreground"
              image-restriction="stencil" :stencil-size="stencilSize" :stencil-props="{
                lines: {},
                handlers: {},
                movable: false,
                scalable: false,
                aspectRatio: 10 / 10,
                previewClass: 'twitter-cropper__stencil',
              }" :transitions="false" :canvas="true" :debounce="false" :default-size="defaultSize" :min-width="170"
              :min-height="170" :src="items[total].pictrue1" @change="onChange" />
            <navigation :zoom="zoom" @change="onZoom" />
            <div align="center">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="" @click="crop()">
                ใช้ </b-button>&nbsp;&nbsp;&nbsp;
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="danger"
                @click="open_default()">
                ปิด
              </b-button>
            </div>
          </b-form-group>
        </div>
      </div>
    </b-modal>
    <!-- -------- porduct 2 type 2 ---------- -->
    <b-modal v-model="set_product2_type2" id="modal-center" centered size="lg" title="เลือกประเภทการ์ด" ok-only
      ok-variant="danger" ok-title="ยกเลิก" no-close-on-backdrop hide-footer hide-header-close>
      <div>
        <!-- ---1--- -->
        <div>
          <b-form-group>
            <b-form-file v-model="file2" placeholder="รูปภาพ สินค้า 1" drop-placeholder="Drop file here..." type="file"
              accept=".jpeg,.png,.jpg" :hidden="true" @change="onFileChange2" size="sm" />

            <cropper style="height: 470px" ref="cropper" class="twitter-cropper"
              background-class="twitter-cropper__background" foreground-class="twitter-cropper__foreground"
              image-restriction="stencil" :stencil-size="stencilSize" :stencil-props="{
                lines: {},
                handlers: {},
                movable: false,
                scalable: false,
                aspectRatio: 10 / 10,
                previewClass: 'twitter-cropper__stencil',
              }" :transitions="false" :canvas="true" :debounce="false" :default-size="defaultSize" :min-width="170"
              :min-height="170" :src="items[total].pictrue2" @change="onChange" />
            <navigation :zoom="zoom" @change="onZoom" />
            <div align="center">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="" @click="crop2()">
                ใช้ </b-button>&nbsp;&nbsp;&nbsp;
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="danger"
                @click="open_default()">
                ปิด
              </b-button>
            </div>
          </b-form-group>
        </div>
      </div>
    </b-modal>
    <!-- -------- card End ---------- -->
    <b-modal id="modal-sm" cancel-variant="primary" ok-only centered ok-variant="primary" ok-title="ตกลง" size="sm">
      <b-row>
        <b-col cols="6">
          <div id="card1">
            <b-card :img-src="require('@/assets/images/card_message/type_end1.png')" style="margin-bottom: 0.2em">
              <b-form-radio value="เรียบง่าย" v-model="type_end">
                เรียบง่าย
              </b-form-radio>
            </b-card>
          </div>
        </b-col>
        <b-col cols="6">
          <div id="card2">
            <b-card :img-src="require('@/assets/images/card_message/type_end2.png')" style="margin-bottom: 0.2em">
              <b-form-radio value="รูป" v-model="type_end"> รูป </b-form-radio>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <!-- -------- img ---------- -->
    <b-modal v-model="set_card_img_end" id="modal-center" centered size="lg" title="เลือกประเภทการ์ด" ok-only
      ok-variant="danger" ok-title="ยกเลิก" no-close-on-backdrop hide-footer hide-header-close>
      <div>
        <!-- -------- img ---------- -->
        <b-form-group>
          <b-form-file v-model="file4" placeholder="รูปภาพ" type="file" accept=".jpeg,.png,.jpg" :hidden="true"
            @change="onFileChange_img_end" size="sm" />

          <cropper style="height: 470px" ref="cropper" class="twitter-cropper"
            background-class="twitter-cropper__background" foreground-class="twitter-cropper__foreground"
            image-restriction="stencil" :stencil-size="stencilSize" :stencil-props="{
              lines: {},
              handlers: {},
              movable: false,
              scalable: false,
              aspectRatio: 10 / 8.3,
              previewClass: 'twitter-cropper__stencil',
            }" :transitions="false" :canvas="true" :debounce="false" :default-size="defaultSize" :min-width="170"
            :min-height="170" :src="pictrue_end" @change="onChange" />
          <navigation :zoom="zoom" @change="onZoom" />
          <div align="center">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="" @click="crop_img_end()">
              ใช้ </b-button>&nbsp;&nbsp;&nbsp;
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="danger" @click="open_default()">
              ปิด
            </b-button>
          </div>
        </b-form-group>
      </div>
    </b-modal>
    <!-- model_type -->
    <b-modal v-model="model_type" id="modal-type" centered title="เลือกประเภทการ์ด" ok-only ok-variant="primary"
      ok-title="ตกลง">
      <b-row>
        <b-col cols="4" @click="settype('สินค้า')">
          <div id="card1">
            <b-card :img-src="require('@/assets/images/card_message/1.png')" style="margin-bottom: 0.2em">
              <b-form-radio value="สินค้า" v-model="type_card">
                สินค้า
              </b-form-radio>
            </b-card>
          </div>
        </b-col>
        <b-col cols="4" @click="settype('บุคคล')">
          <div id="card2">
            <b-card :img-src="require('@/assets/images/card_message/2.png')" style="margin-bottom: 0.2em">
              <b-form-radio value="บุคคล" v-model="type_card">
                บุคคล
              </b-form-radio>
            </b-card>
          </div>
        </b-col>
        <b-col cols="4" @click="settype('รูปภาพ')">
          <div id="card3">
            <b-card :img-src="require('@/assets/images/card_message/3.png')" style="margin-bottom: 0.2em">
              <b-form-radio value="รูปภาพ" v-model="type_card">
                รูปภาพ
              </b-form-radio>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-modal>
<!-- 
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <div id="flex2">
      <div class="LySlider">
        <div class="lyInner">
          <div class="lyItem LyMe">
            <div class="T1 fxLTR" dir="ltr">
              <div class="t1Hero"></div>
              <div class="t1Header"></div>
              <div class="t1Body">
                <div class="MdBx vr" style="padding: 0px">
                  <div class="MdImg ExCover ExFull">
                    <div>
                      <a style="padding-bottom: 100%">
                        <span style="
                            background-image: url('https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip1.jpg');
                          "></span>
                      </a>
                    </div>
                  </div>

                  <div class="MdBx vr ExAbs" style="
                      width: 53px;
                      max-width: 53px;
                      height: 25px;
                      background-color: #ff334b !important;
                      border-radius: 20px;
                      top: 18px;
                      left: 18px;
                    ">
                    <div class="MdTxt ExAlgC ExXs" style="color: #ffffff; top: 3px">
                      <p>SALE</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="t1Footer"></div>
            </div>
          </div>
          <div class="lyItem LyMe">
            <div class="T1 fxLTR" dir="ltr">
              <div class="t1Hero"></div>
              <div class="t1Header"></div>
              <div class="t1Body">
                <div class="MdBx vr" style="padding: 0px">
                  <div class="MdImg ExCover ExFull">
                    <div>
                      <a style="padding-bottom: 100%">
                        <span style="
                            background-image: url('https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip1.jpg');
                          "></span>
                      </a>
                    </div>
                  </div>

                  <div class="MdBx vr ExAbs" style="
                      width: 53px;
                      max-width: 53px;
                      height: 25px;
                      background-color: #ff334b !important;
                      border-radius: 20px;
                      top: 18px;
                      left: 18px;
                    ">
                    <div class="MdTxt ExAlgC ExXs" style="color: #ffffff; top: 3px">
                      <p>SALE</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="t1Footer"></div>
            </div>
          </div>
          <div class="lyItem LyMe">
            <div class="T1 fxLTR" dir="ltr">
              <div class="t1Hero"></div>
              <div class="t1Header"></div>
              <div class="t1Body">
                <div class="MdBx vr" style="padding: 0px">
                  <div class="MdImg ExCover ExFull">
                    <div>
                      <a style="padding-bottom: 100%">
                        <span style="
                            background-image: url('https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip1.jpg');
                          "></span>
                      </a>
                    </div>
                  </div>

                  <div class="MdBx vr ExAbs" style="
                      width: 53px;
                      max-width: 53px;
                      height: 25px;
                      background-color: #ff334b !important;
                      border-radius: 20px;
                      top: 18px;
                      left: 18px;
                    ">
                    <div class="MdTxt ExAlgC ExXs" style="color: #ffffff; top: 3px">
                      <p>SALE</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="t1Footer"></div>
            </div>
          </div>
          <div class="lyItem LyMe">
            <div class="T1 fxLTR" dir="ltr">
              <div class="t1Hero"></div>
              <div class="t1Header"></div>
              <div class="t1Body">
                <div class="spcSm" style="margin-top: 40%">
                  <div class="MdBtn ExBtnL fl1 grvC" id="8d1efea2-4017-4c89-8931-98a5f4f141f2">
                    <a href="https://linecorp.com" target="_blank">
                      <div>See more</div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="t1Footer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div id="flex5">
      <div class="LySlider">
        <div class="lyInner">
          <div class="lyItem LyMe">
            <div class="T1 fxLTR" dir="ltr">
              <div class="t1Hero"></div>
              <div class="t1Header">
                <div class="MdBx vr" style="padding: 0px">
                  <div class="MdBx hr">
                    <div class="MdImg ExCover fl1 ExFull grvC">
                      <div>
                        <a style="padding-bottom: 65%">
                          <span style="
                              background-image: url('https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip1.jpg');
                            "></span>
                        </a>
                      </div>
                    </div>

                    <div class="MdBx hr fl0 ExAbs" style="
                        width: 48px;
                        max-width: 48px;
                        height: 25px;
                        background-color: #ec3d44 !important;
                        border-radius: 100px;
                        top: 18px;
                        left: 18px;
                        padding: 2px;
                        padding-left: 4px;
                        padding-right: 4px;
                      ">
                      <div class="ExAlgC grvC ExXs" style="color: #ffffff">
                        <p>NEW</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="t1Body">
                <div class="MdBx vr" style="background-color: #fff !important ; padding: 20px">
                  <div align="left">
                    <h3>ชื่อการ์ด</h3>
                  </div>
                  <div align="left">
                    <p>คำอธิบาย</p>
                  </div>
                  <div align="right">฿ 999999</div>
                </div>
              </div>
              <div class="t1Footer"></div>
            </div>
          </div>

          <div class="lyItem LyMe">
            <div class="T1 fxLTR" dir="ltr">
              <div class="t1Hero"></div>
              <div class="t1Header">
                <div class="MdBx vr" style="padding: 0px">
                  <div class="MdBx hr">
                    <div class="MdImg ExCover fl1 ExFull grvC">
                      <div>
                        <a style="padding-bottom: 130.66666666666666%">
                          <span style="
                              background-image: url('https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip2.jpg');
                            "></span>
                        </a>
                      </div>
                    </div>
                    <div class="MdImg ExCover fl1 ExFull grvC">
                      <div>
                        <a style="padding-bottom: 130.66666666666666%">
                          <span style="
                              background-image: url('https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip3.jpg');
                            "></span>
                        </a>
                      </div>
                    </div>

                    <div class="MdBx hr fl0 ExAbs" style="
                        width: 48px;
                        max-width: 48px;
                        height: 25px;
                        background-color: #ec3d44 !important;
                        border-radius: 100px;
                        top: 18px;
                        left: 18px;
                        padding: 2px;
                        padding-left: 4px;
                        padding-right: 4px;
                      ">
                      <div class="ExAlgC grvC ExXs" style="color: #ffffff">
                        <p>NEW</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="t1Body">
                <div class="MdBx vr" style="background-color: #fff !important ; padding: 20px">
                  <div align="left">
                    <h3>ชื่อการ์ด</h3>
                  </div>
                  <div align="left">
                    <p>คำอธิบาย</p>
                  </div>
                  <div align="right">฿ 999999</div>
                </div>
              </div>
              <div class="t1Footer"></div>
            </div>
          </div>

          <div class="lyItem LyMe">
            <div class="T1 fxLTR" dir="ltr">
              <div class="t1Hero"></div>
              <div class="t1Header">
                <div class="MdBx vr" style="padding: 0px">
                  <div class="MdBx hr">
                    <div class="MdImg ExCover fl1 ExFull grvC">
                      <div>
                        <a style="padding-bottom: 130.66666666666666%">
                          <span style="
                              background-image: url('https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip4.jpg');
                            "></span>
                        </a>
                      </div>
                    </div>
                    <div class="MdBx vr fl1">
                      <div class="MdImg ExCover ExFull grvC">
                        <div>
                          <a style="padding-bottom: 65.33333333333333%">
                            <span style="
                                background-image: url('https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip5.jpg');
                              "></span>
                          </a>
                        </div>
                      </div>
                      <div class="MdImg ExCover ExFull grvC">
                        <div>
                          <a style="padding-bottom: 65.33333333333333%">
                            <span style="
                                background-image: url('https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip6.jpg');
                              "></span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="MdBx hr fl0 ExAbs" style="
                        width: 48px;
                        max-width: 48px;
                        height: 25px;
                        background-color: #ec3d44 !important;
                        border-radius: 100px;
                        top: 18px;
                        left: 18px;
                        padding: 2px;
                        padding-left: 4px;
                        padding-right: 4px;
                      ">
                      <div class="ExAlgC grvC ExXs" style="color: #ffffff">
                        <p>NEW</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="t1Body">
                <div class="MdBx vr" style="background-color: #fff !important ; padding: 20px">
                  <div align="left">
                    <h3>ชื่อการ์ด</h3>
                  </div>
                  <div align="left">
                    <p>คำอธิบาย</p>
                  </div>
                  <div align="right">฿ 999999</div>
                </div>
              </div>
              <div class="t1Footer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div id="flex6">
      <div class="LySlider">
        <div class="lyInner">
          <div class="lyItem LyMe">
            <div class="T1 fxLTR" dir="ltr">
              <div class="t1Hero"></div>
              <div class="t1Header">
                <div align="center" class="mt-2 mb-2">
                  <img src="https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip13.jpg"
                    width="120px" height="120px" style="border-radius: 50%" />
                </div>
              </div>
              <div class="t1Body">
                <h3 class="mb-1">ชื่อ</h3>
                <div align="center" style="margin-left: 32%">
                  <div class="mr-1" style="
                      width: 53px;
                      max-width: 53px;
                      height: 25px;
                      background-color: #ff334b !important;
                      border-radius: 20px;
                      top: 18px;
                      left: 18px;
                      float: left !important;
                    ">
                    <div class="MdTxt ExAlgC ExXs" style="color: #ffffff; top: 3px">
                      <p>แท็ก1</p>
                    </div>
                  </div>
                  <div class="mr-1" style="
                      width: 53px;
                      max-width: 53px;
                      height: 25px;
                      background-color: #ff334b !important;
                      border-radius: 20px;
                      top: 18px;
                      left: 18px;
                      float: left !important;
                    ">
                    <div class="MdTxt ExAlgC ExXs" style="color: #ffffff; top: 3px">
                      <p>แท็ก2</p>
                    </div>
                  </div>
                </div>
                <br />
                <h6>ชื่อ</h6>
                <br /><br />
              </div>
              <div class="t1Footer"></div>
            </div>
          </div>
          <div class="lyItem LyMe">
            <div class="T1 fxLTR" dir="ltr">
              <div class="t1Hero"></div>
              <div class="t1Header">
                <div align="center" class="mt-2 mb-2">
                  <img src="https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip11.jpg"
                    width="120px" height="120px" style="border-radius: 50%" />
                </div>
              </div>
              <div class="t1Body">
                <h3 class="mb-1">ชื่อ</h3>
                <div align="center" style="margin-left: 25%">
                  <div class="mr-1" style="
                      width: 53px;
                      max-width: 53px;
                      height: 25px;
                      background-color: #ff334b !important;
                      border-radius: 20px;
                      top: 18px;
                      left: 18px;
                      float: left !important;
                    ">
                    <div class="MdTxt ExAlgC ExXs" style="color: #ffffff; top: 3px">
                      <p>แท็ก1</p>
                    </div>
                  </div>
                  <div class="mr-1" style="
                      width: 53px;
                      max-width: 53px;
                      height: 25px;
                      background-color: #ff334b !important;
                      border-radius: 20px;
                      top: 18px;
                      left: 18px;
                      float: left !important;
                    ">
                    <div class="MdTxt ExAlgC ExXs" style="color: #ffffff; top: 3px">
                      <p>แท็ก2</p>
                    </div>
                  </div>
                  <div class="mr-1" style="
                      width: 53px;
                      max-width: 53px;
                      height: 25px;
                      background-color: #ff334b !important;
                      border-radius: 20px;
                      top: 18px;
                      left: 18px;
                      float: left !important;
                    ">
                    <div class="MdTxt ExAlgC ExXs" style="color: #ffffff; top: 3px">
                      <p>แท็ก3</p>
                    </div>
                  </div>
                </div>
                <br />
                <h6>ชื่อ</h6>
                <br /><br />
              </div>
              <div class="t1Footer"></div>
            </div>
          </div>
          <div class="lyItem LyMe">
            <div class="T1 fxLTR" dir="ltr">
              <div class="t1Hero"></div>
              <div class="t1Header">
                <div align="center" class="mt-2 mb-2">
                  <img src="https://scdn.line-apps.com/n/channel_devcenter/img/flexsnapshot/clip/clip8.jpg"
                    width="120px" height="120px" style="border-radius: 50%" />
                </div>
              </div>
              <div class="t1Body">
                <h3 class="mb-1">ชื่อ</h3>
                <div align="center" style="margin-left: 40%">
                  =

                  <div class="mr-1" style="
                      width: 53px;
                      max-width: 53px;
                      height: 25px;
                      background-color: #ff334b !important;
                      border-radius: 20px;
                      top: 18px;
                      left: 18px;
                      float: left !important;
                    ">
                    <div class="MdTxt ExAlgC ExXs" style="color: #ffffff; top: 3px">
                      <p>แท็ก1</p>
                    </div>
                  </div>
                </div>
                <br />
                <h6>ชื่อ</h6>
                <br /><br />
              </div>
              <div class="t1Footer"></div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  VBModal,
  BAlert,
  BCardCode,
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
  BCardText,
  BCardTitle,
  BCard,
  BFormFile,
  BFormRadio,
  BFormInput,
  BAvatar,
  BBadge,
  BTabs,
  BTab,
  BFormSelect,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import api from "@/api";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import Navigation from "./Navigation";
import { heightFade } from "@core/directives/animations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    Cropper,
    Navigation,
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BRow,
    BCol,
    BFormTextarea,
    BCardText,
    BCardTitle,
    BCard,
    BFormFile,
    BFormRadio,
    BFormInput,
    BAvatar,
    BBadge,
    BTabs,
    BTab,
    ToastificationContent,
    BFormSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      items: [
        {
          id: 1,
          number_of_pictures: 1,
          options: [
            { value: 1, text: "1" },
            { value: 2, text: "2" },
            { value: 3, text: "3" },
          ],
          pictrue1: require("@/assets/images/card_message/default.png"),
          pictrue2: require("@/assets/images/card_message/default.png"),
          pictrue3: require("@/assets/images/card_message/default.png"),
          text_banner: "",
          name: "",
          tag1: "",
          tag2: "",
          tag3: "",
          tag_class1: "",
          tag_class2: "",
          tag_class3: "",
          description: "",
          price: "",
          type: "",
          setclassBanner: "",
          setclassTag: "",
        },
      ],
      items_end: [],
      text_end: "",
      link_end: "",
      type_end: "เรียบง่าย",
      pictrue_end: require("@/assets/images/card_message/default.png"),
      nextTodoId: 2,
      zoom: 0,
      name_card: "",
      model_addsp1: false,
      model_addsp2: false,
      model_addsp3: false,
      model_setect: false,
      set_img: false,
      set_profile: false,
      set_product1: false,
      set_product2: false,
      set_product3: false,
      set_product1_type1: false,
      set_product2_type1: false,
      set_product2_type2: false,
      set_card_img_end: false,
      store_id: localStorage.getItem("store_id"),
      file1: null,
      file2: null,
      file3: null,
      file4: null,
      number: null,
      reserve_pictrue1: null,
      reserve_pictrue2: null,
      reserve_pictrue3: null,
      reserve_pictrue_end: null,
      type_card: null,
      total: 0,
      tabIndex: 0,
      model_type: false,
      check: {
        showDismissibleAlert: false,
        file1: false,
        file2: false,
        file3: false,
        text_end: false,
        pictrue_end: false,
      },
    };
  },

  mounted() {
    this.tabIndex = this.items.length + 1;
  },
  watch: {
    type_card: function (val) {
      if (val === "สินค้า") {
        document.getElementById("card1").style.border = "2px solid blue";
        document.getElementById("card2").style.border = "";
        document.getElementById("card3").style.border = "";
      }
      if (val === "บุคคล") {
        document.getElementById("card1").style.border = "";
        document.getElementById("card2").style.border = "2px solid blue";
        document.getElementById("card3").style.border = "";
      }
      if (val === "รูปภาพ") {
        document.getElementById("card1").style.border = "";
        document.getElementById("card2").style.border = "";
        document.getElementById("card3").style.border = "2px solid blue";
      }
      this.file1 = null;
      this.file2 = null;
      this.file3 = null;
      this.file4 = null;

      this.pictrue_end = require("@/assets/images/card_message/default.png");
      for (let i = 0; i < this.items.length; i++) {
        this.items[
          i
        ].pictrue1 = require("@/assets/images/card_message/default.png");
        this.items[
          i
        ].pictrue2 = require("@/assets/images/card_message/default.png");
        this.items[
          i
        ].pictrue3 = require("@/assets/images/card_message/default.png");
      }
    },
  },
  methods: {
    settype(val) {
      // console.log('val :>> ', val);
      this.type_card = val;
      // console.log('this.items.length :>> ', this.items.length);
      this.tabIndex = 0;
    },
    repeateAgain() {
      if (this.items.length < 9) {
        this.tabIndex = this.items.length;
        this.items.push({
          id: (this.nextTodoId += this.nextTodoId),
          number_of_pictures: 1,
          options: [
            { value: 1, text: "1" },
            { value: 2, text: "2" },
            { value: 3, text: "3" },
          ],
          pictrue1: require("@/assets/images/card_message/default.png"),
          pictrue2: require("@/assets/images/card_message/default.png"),
          pictrue3: require("@/assets/images/card_message/default.png"),
          text_banner: "",
          name: "",
          tag1: "",
          tag2: "",
          tag3: "",
          description: "",
          link: "",
          price: "",
          type: "",
          setclassBanner: "",
          setclassTag: "",
          tag_class1: "",
          tag_class2: "",
          tag_class3: "",
        });
        console.log(" this.items :>> ", this.items);
        setTimeout(() => {
          let tab = document.getElementById(
            "tab" + (this.items.length - 1) + "___BV_tab_button__"
          );
          tab.click();
        }, "100");
      }
    },
    removeItem(index) {
      this.items[index] = [];
      this.items.splice(index, 1);
    },
    crop_img_end() {
      const result = this.$refs.cropper.getResult();
      const params = { pictrue: result.canvas.toDataURL("image/jpg") };

      this.resizeImage(result.canvas.toDataURL("image/jpg"), 1000, 1000).then(
        (base64) => {
          const params = { pictrue: base64 };
          api
            .post("convert_base64_decode", params, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              this.open_default();
              this.pictrue_end = response.data;
            })
            .catch((err) => {
              this.open_default();
              this.pictrue_end = require("@/assets/images/card_message/default.png");
            });
        }
      );
    },
    crop() {
      const result = this.$refs.cropper.getResult();
      console.log("result :>> ", result);
      this.resizeImage(result.canvas.toDataURL("image/jpg"), 1000, 1000).then(
        (base64) => {
          const params = { pictrue: base64 };
          api
            .post("convert_base64_decode", params, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              console.log("response.data :>> ", response.data);
              this.open_default();
              this.items[this.total].pictrue1 = response.data;
            })
            .catch((err) => {
              this.open_default();
              this.items[
                this.total
              ].pictrue1 = require("@/assets/images/card_message/default.png");
            });
        }
      );
    },
    crop2() {
      const result = this.$refs.cropper.getResult();
      console.log("result2 :>> ", result);
      this.resizeImage(result.canvas.toDataURL("image/jpg"), 1000, 1000).then(
        (base64) => {
          const params = { pictrue: base64 };
          api
            .post("convert_base64_decode", params, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              console.log("response.data :>> ", response.data);
              this.open_default();
              this.items[this.total].pictrue2 = response.data;
            })
            .catch((err) => {
              this.open_default();
              this.items[
                this.total
              ].pictrue2 = require("@/assets/images/card_message/default.png");
            });
        }
      );
    },
    crop3() {
      const result = this.$refs.cropper.getResult();
      console.log("result :>> ", result);
      this.resizeImage(result.canvas.toDataURL("image/jpg"), 1000, 1000).then(
        (base64) => {
          const params = { pictrue: base64 };
          api
            .post("convert_base64_decode", params, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              console.log("response.data :>> ", response.data);
              this.open_default();
              this.items[this.total].pictrue3 = response.data;
            })
            .catch((err) => {
              this.open_default();
              this.items[
                this.total
              ].pictrue3 = require("@/assets/images/card_message/default.png");
            });
        }
      );
    },
    open_product1(number, array) {
      this.set_img = false;
      this.set_product1 = true;
      this.set_product2 = false;
      this.set_product3 = false;
      this.set_profile = false;
      this.total = array;
      this.items[array].type = "product";
      this.number = number;
      this.reserve_pictrue1 = this.items[array].pictrue1;
    },
    open_product2(number, array) {
      this.set_img = false;
      this.set_product1 = false;
      this.set_product2 = true;
      this.set_product3 = false;
      this.set_profile = false;
      this.total = array;
      this.items[array].type = "product";
      this.number = number;
      this.reserve_pictrue2 = this.items[array].pictrue2;
    },
    open_product3(number, array) {
      this.set_img = false;
      this.set_product1 = false;
      this.set_product2 = false;
      this.set_product3 = true;
      this.set_profile = false;
      this.total = array;
      this.items[array].type = "product";
      this.number = number;
      this.reserve_pictrue3 = this.items[array].pictrue3;
    },
    open_product1_type1(number, array) {
      this.set_product1_type1 = true;
      this.set_img = false;
      this.set_product1 = false;
      this.set_product2 = false;
      this.set_product3 = false;
      this.set_profile = false;
      this.total = array;
      this.reserve_pictrue1 = this.items[array].pictrue1;
    },
    open_product2_type1(number, array) {
      this.set_product2_type1 = true;
      this.set_img = false;
      this.set_product1 = false;
      this.set_product2 = false;
      this.set_product3 = false;
      this.set_profile = false;
      this.total = array;
      this.reserve_pictrue1 = this.items[array].pictrue1;
    },
    open_product2_type2(number, array) {
      this.set_product2_type2 = true;
      this.set_img = false;
      this.set_product1 = false;
      this.set_product2 = false;
      this.set_product3 = false;
      this.set_profile = false;
      this.total = array;
      this.reserve_pictrue2 = this.items[array].pictrue2;
    },
    open_img(number, array) {
      this.set_img = true;
      this.set_product = false;
      this.set_profile = false;
      this.items[array].type = "img";
      this.total = array;
      this.reserve_pictrue1 = this.items[array].pictrue1;
    },
    open_profile(number, array) {
      this.set_img = false;
      this.set_product = false;
      this.set_profile = true;
      this.total = array;
      this.items[array].type = "profile";
      this.reserve_pictrue_end = null;
      this.reserve_pictrue1 = null;
      this.reserve_pictrue2 = null;
      this.reserve_pictrue3 = null;
      this.reserve_pictrue1 = this.items[array].pictrue1;
    },
    img_card_end() {
      this.set_card_img_end = true;
      this.reserve_pictrue_end = this.pictrue_end;
    },
    open_default(pictrue) {
      this.set_img = false;
      this.set_product1 = false;
      this.set_product2 = false;
      this.set_product3 = false;
      this.set_profile = false;
      this.set_card_img_end = false;
      this.model_type = false;
      this.set_product1_type1 = false;
      this.set_product2_type1 = false;
      this.set_product2_type2 = false;
      this.zoom = 0;
      if (this.reserve_pictrue1) {
        this.items[this.total].pictrue1 = this.reserve_pictrue1;
      }
      if (this.reserve_pictrue2) {
        this.items[this.total].pictrue2 = this.reserve_pictrue1;
      }
      if (this.reserve_pictrue3) {
        this.items[this.total].pictrue3 = this.reserve_pictrue1;
      }
      if (this.reserve_pictrue_end) {
        this.pictrue_end = this.reserve_pictrue_end;
      }
      this.reserve_pictrue1 = null;
      this.reserve_pictrue2 = null;
      this.reserve_pictrue3 = null;
      this.reserve_pictrue_end = null;
    },
    defaultSize({ imageSize }) {
      return {
        width: Math.min(imageSize.height, imageSize.width),
        height: Math.min(imageSize.height, imageSize.width),
      };
    },
    stencilSize({ boundaries }) {
      return {
        width: Math.min(boundaries.height, boundaries.width) - 48,
        height: Math.min(boundaries.height, boundaries.width) - 48,
      };
    },
    onChange(result) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        const { coordinates, imageSize } = cropper;
        if (
          imageSize.width / imageSize.height >
          coordinates.width / coordinates.height
        ) {
          // Determine the position of slider bullet
          // It's 0 if the stencil has the maximum size and it's 1 if the has the minimum size
          this.zoom =
            (cropper.imageSize.height - cropper.coordinates.height) /
            (cropper.imageSize.height - cropper.sizeRestrictions.minHeight);
        } else {
          this.zoom =
            (cropper.imageSize.width - cropper.coordinates.width) /
            (cropper.imageSize.width - cropper.sizeRestrictions.minWidth);
        }
      }
    },
    onZoom(value) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        if (cropper.imageSize.height < cropper.imageSize.width) {
          const minHeight = cropper.sizeRestrictions.minHeight;
          const imageHeight = cropper.imageSize.height;
          // Determine the current absolute zoom and the new absolute zoom
          // to calculate the sought relative zoom value
          cropper.zoom(
            (imageHeight - this.zoom * (imageHeight - minHeight)) /
            (imageHeight - value * (imageHeight - minHeight))
          );
        } else {
          const minWidth = cropper.sizeRestrictions.minWidth;
          const imageWidth = cropper.imageSize.width;
          cropper.zoom(
            (imageWidth - this.zoom * (imageWidth - minWidth)) /
            (imageWidth - value * (imageWidth - minWidth))
          );
        }
      }
    },
    onFileChange(e) {
      this.file1 = e.target.files[0];
      const image = e.target.files[0];
      this.items[this.total].pictrue1 = URL.createObjectURL(image);
    },
    onFileChange2(e) {
      this.file2 = e.target.files[0];
      const image = e.target.files[0];
      this.items[this.total].pictrue2 = URL.createObjectURL(image);
    },
    onFileChange3(e) {
      this.file3 = e.target.files[0];
      const image = e.target.files[0];
      this.items[this.total].pictrue3 = URL.createObjectURL(image);
    },
    onFileChange_img_end(e) {
      this.file3 = e.target.files[0];
      const image = e.target.files[0];
      this.pictrue_end = URL.createObjectURL(image);
    },
    showmodeladdSP() {
      this.model_addsp = false;
      this.model_setect = true;
    },
    save() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "ตรวจสอบข้อมูล",
            icon: "CheckIcon",
            text: "กำลังตรวจสอบข้อมูล",
            variant: "warning",
          },
        },
        {
          timeout: 4000,
        }
      );
      this.items_end = [];

      if (this.text_end) {
        this.items_end.push({
          text_end: this.text_end,
          link_end: this.link_end,
          type_end: this.type_end,
          pictrue_end: this.pictrue_end,
          type: "การ์ดปิดท้าย",
        });
      }
      console.log("this.items :>> ", this.items);

      if (this.name_card && this.text_end) {
        this.insert();
      }

      if (!this.file1) {
        this.check.file1 = true;
      }
      if (!this.file1 && !this.file2 && !this.file3) {
        this.check.file3 = true;
      }
      if (!this.name_card) {
        this.check.showDismissibleAlert = true;
      }
      if (!this.text_end) {
        setTimeout(() => {
          let tab10 = document.getElementById("tab10___BV_tab_button__");
          tab10.click();
          this.check.text_end = true;
        }, "300");
      }
      if (this.type_end === "รูป")
        if (this.pictrue_end.search("default") > 0) {
          this.check.pictrue_end = true;
        }
    },
    insert() {
      const params = {
        items_end: this.items_end,
        items: this.items,
        type_card: this.type_card,
        name_card: this.name_card,
        store_id: localStorage.getItem("store_id"),
      };
      api
        .post("add_card_message", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "เพิ่มข้อมูล",
                icon: "SaveIcon",
                text: "บันทึกข้อมูลของคุณเรียบร้อย",
                variant: "success",
              },
            },
            {
              timeout: 4000,
            }
          );
          setTimeout(() => {
            this.$router.push({
              path: "/MessageCard",
            });
          }, "2000");
        });
    },
    refresh() {
      this.$emit("loaddata", "loaddata");
    },
    setclass(a, index) {
      var text_banner = document.getElementById("text_banner" + index);
      text_banner.className = "inner_position_top " + a;
      this.items[index].setclassBanner = a;
    },
    setclassTag(a, t, index) {
      var text_banner = document.getElementById(t + index);
      this.items[index].setclassTag = a;
      if (t === "tag1") {
        this.items[index].tag_class1 = a;
        text_banner.className = a;
      }
      if (t === "tag2") {
        this.items[index].tag_class2 = a;
        text_banner.className = a;
      }
      if (t === "tag3") {
        this.items[index].tag_class3 = a;
        text_banner.className = a;
      }
      if (a === "tag4") {
        text_banner.style.color = "#000";
      }
    },
    resizeImage(base64Str, maxWidth = 400, maxHeight = 350) {
      return new Promise((resolve) => {
        let img = new Image();
        img.src = base64Str;
        img.onload = () => {
          let canvas = document.createElement("canvas");
          const MAX_WIDTH = maxWidth;
          const MAX_HEIGHT = maxHeight;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          resolve(canvas.toDataURL());
        };
      });
    },
  },
};
</script>
<style scoped lang="scss">
.grid-container {
  display: grid;
  grid-template-columns: auto auto;
}

.grid-container3 {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-row-gap: 50px;
  margin-left: 2em;
}

.grid-container3>div {
  text-align: center;
  grid-row-gap: 50px;
}

.grid-container>div {
  text-align: center;
}

.inner_position_top {
  position: absolute;
  display: block;
  top: 10px;
  margin-left: 0em;
  z-index: 999;
  color: #edf2f4;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 30px;
  padding-top: 5px;
}

.twitter-cropper {
  height: 521px;

  &__background {
    background-color: #edf2f4;
  }

  &__foreground {
    background-color: #edf2f4;
  }

  &__stencil {
    border: solid 5px rgb(29, 161, 242);
  }
}

.dot {
  height: 40px;
  width: 40px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  align-content: center;
  padding-top: 8px;
  color: #edf2f4;
}

.a1 {
  background-color: #666f86 !important;
  color: #fff !important;
}

.a2 {
  background-color: #fff !important;
  color: #000 !important;
  border: 0.01px solid rgba(83, 83, 83, 0.698) !important;
}

.a3 {
  background-color: #ec4e3d !important;
  color: #fff !important;
}

.a4 {
  background-color: #eb8436 !important;
  color: #fff !important;
}

.a5 {
  background-color: #00b800 !important;
  color: #fff !important;
}

.a6 {
  background-color: #5b82db !important;
  color: #fff !important;
}
</style>
